import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Autocomplete,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ListSubheader,
  Link,
  Tooltip,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { createFilterOptions } from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import LockIcon from "@mui/icons-material/Lock";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/material/styles";
import loop4Img from "./loop4.png";
import { isSessionValid } from "./utils";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.updateLocale("en", {
  weekStart: 1, // Set Monday as the first day of the week, for right display in calendars
});

dayjs.tz.setDefault("Europe/Helsinki");

const DEFAULT_HOURS_IN_SECONDS = 3600;
const MIN_HOURS_IN_SECONDS = 60;

const WorkHoursTable = ({ authToken, onLogout, onViewChange }) => {
  const [workHours, setWorkHours] = useState([]);
  const [clients, setClients] = useState([]);
  const [activities, setActivities] = useState([]);
  const [types, setTypes] = useState([]);
  const [editing, setEditing] = useState(null);
  const defaultTime = dayjs().subtract(DEFAULT_HOURS_IN_SECONDS, "seconds");
  const [form, setForm] = useState({
    date: defaultTime,
    hours: DEFAULT_HOURS_IN_SECONDS,
    description: "",
    client: "",
    activity: "",
    type: "",
  });
  const [editForm, setEditForm] = useState({
    date: defaultTime,
    hours: DEFAULT_HOURS_IN_SECONDS,
    description: "",
    client: "",
    activity: "",
    type: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [runningTime, setRunningTime] = useState(0);
  const [runningTimerId, setRunningTimerId] = useState(null);
  const [loading, setLoading] = useState(true);
  const timerRef = useRef(null);
  const [suggestions, setSuggestions] = useState([]);

  const generateClientActivityMenuItems = () => {
    const menuItems = [];
    clients.forEach((client) => {
      menuItems.push(
        <ListSubheader key={`client-${client._id}`}>
          {client.name}
        </ListSubheader>
      );
      activities
        .filter((activity) => activity.clientId === client._id)
        .forEach((activity) => {
          menuItems.push(
            <MenuItem key={activity._id} value={activity._id}>
              {activity.name}
            </MenuItem>
          );
        });
    });
    return menuItems;
  };

  const getClientActivityDisplay = (workHour) => {
    const activity = activities.find(
      (activity) => activity._id === workHour.activity
    );
    const client = clients.find((client) => client._id === activity?.clientId);
    return client ? `${client.name}: ${activity.name}` : activity?.name || "";
  };

  useEffect(() => {
    const fetchRunningTimerData = async () => {
      try {
        const runningTimerResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/workhours/running`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (runningTimerResponse.ok) {
          const runningTimerData = await runningTimerResponse.json();
          if (runningTimerData.running !== false) {
            setForm({
              ...runningTimerData,
              date: dayjs.unix(runningTimerData.date),
            });
            setRunningTimerId(runningTimerData._id);
            const startTime = dayjs.unix(runningTimerData.date);
            setRunningTime(dayjs().diff(startTime, "seconds"));
            setIsPlaying(true);
            if (!timerRef.current)
              timerRef.current = setInterval(() => {
                setRunningTime((prevTime) => prevTime + 1);
              }, 1000);
          } else {
            setIsPlaying(false);
            clearInterval(timerRef.current);
          }
        } else {
          console.error(
            "Failed to fetch running timer:",
            runningTimerResponse.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching running timer:", error);
      }
    };

    const fetchData = async () => {
      try {
        const workHoursResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/workhours`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        const workHoursData = await workHoursResponse.json();
        const convertedWorkHours = workHoursData.map((workHour) => ({
          ...workHour,
          date: dayjs.unix(workHour.date),
        }));
        setWorkHours(convertedWorkHours);

        if (convertedWorkHours.length > 0) {
          const uniqueDescriptions = Array.from(
            new Set(
              convertedWorkHours
                .map((item) => item.description)
                .filter(
                  (description) => description && description.trim() !== ""
                )
            )
          );
          setSuggestions(uniqueDescriptions);
        }

        const clientsResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/clients`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        const clientsData = await clientsResponse.json();
        setClients(clientsData);

        const activitiesResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/activities`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        const activitiesData = await activitiesResponse.json();
        setActivities(activitiesData);

        const typesResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/types`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        const typesData = await typesResponse.json();
        setTypes(typesData);

        fetchRunningTimerData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        fetchRunningTimerData();
      }
    };

    fetchData();
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [authToken]);

  const warnRowBeingEdited = (forForm) => {
    if (editing && forForm === null) {
      if (
        window.confirm(
          "Do you want to save changes to the currently edited row?"
        )
      ) {
        handleUpdate(editing);
      } else {
        setEditing(null);
      }
    }
  };

  const handleChange = (e, forForm = null) => {
    let value, name;
    if (e.target) {
      value = e.target.value;
      name = e.target.name;
    } else {
      value = e.value;
      name = e.name;
    }

    warnRowBeingEdited(forForm);

    if (forForm === null && !!formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: "" });
    }

    if (name === "activity") {
      const activity = activities.find((activity) => activity._id === value);
      if (forForm === null) {
        setForm({ ...form, activity: value, client: activity.clientId });
      } else if (forForm === "editForm") {
        setEditForm({
          ...editForm,
          activity: value,
          client: activity.clientId,
        });
      }
    } else if (forForm === null) {
      setForm({ ...form, [name]: value });
    } else if (forForm === "editForm") {
      setEditForm({ ...editForm, [name]: value });
    }
  };

  const handleAutocomplete = (event, newValue) => {
    if (!newValue) {
      setForm({ ...form, description: "" });
    } else if (suggestions.includes(newValue)) {
      const selectedRecord = workHours.find(
        (workHour) => workHour.description === newValue && !workHour.running
      );
      if (selectedRecord) {
        const { _id, date, hours, synced, ...rest } = selectedRecord;
        setForm({
          ...form,
          date: form.date,
          hours: DEFAULT_HOURS_IN_SECONDS,
          synced: false,
          ...rest,
        });
      }
    } else {
      setForm({ ...form, description: newValue });
    }
  };

  const onTimeFieldEditIsOtherTimeFieldLocked = (
    fieldBeingEdited,
    otherTimeField
  ) => {
    if (fieldBeingEdited === otherTimeField) return false;
    return onEditingTimeFieldsLockStates[fieldBeingEdited].isDefault
      ? onEditingTimeFieldsLockStates[fieldBeingEdited].default ===
          otherTimeField
      : onEditingTimeFieldsLockStates[fieldBeingEdited].other ===
          otherTimeField;
  };

  const handleStartTimeChange = (date, forForm = null) => {
    warnRowBeingEdited(forForm);
    if (forForm === null) {
      //if onEditingTimeFieldsLockStates["startTime"] currently is set to "endTime" then calculate the hours
      if (onTimeFieldEditIsOtherTimeFieldLocked("startTime", "endTime")) {
        const hours = form.hours - dayjs(date).diff(form.date, "seconds");
        setForm({ ...form, date: dayjs(date), hours });
      } else {
        //else nothing needs to be done on the hours since the end time gets calculated by default: startTime + hours
        setForm({ ...form, date: dayjs(date) });
      }
    } else if (forForm === "editForm") {
      setEditForm({ ...editForm, date: dayjs(date) });
    }
  };

  const handleHoursChange = (time, forForm = null) => {
    warnRowBeingEdited(forForm);
    let hours = time.diff(dayjs("1970-01-01T00:00:00"), "seconds");
    if (forForm === null) {
      if (onTimeFieldEditIsOtherTimeFieldLocked("hours", "endTime")) {
        const date = dayjs(form.date).subtract(hours - form.hours, "seconds");
        setForm({ ...form, date, hours });
      } else {
        //else nothing needs to be done on the hours since the end time gets calculated by default: startTime + hours
        setForm({ ...form, hours });
      }
    } else if (forForm === "editForm") {
      setEditForm({ ...editForm, hours });
    }
  };

  const handleEndTimeChange = (endDate, forForm = null) => {
    warnRowBeingEdited(forForm);
    if (forForm === null) {
      //if onEditingTimeFieldsLockStates["endTime"] currently is set to "hours" then calculate the start time
      if (onTimeFieldEditIsOtherTimeFieldLocked("endTime", "hours")) {
        const date = dayjs(endDate).subtract(form.hours, "seconds");
        setForm({ ...form, date });
      } else {
        //calculate the hours
        const hours = dayjs(endDate).diff(form.date, "seconds");
        setForm({ ...form, hours });
      }
    }
    //not in use in editForm
  };

  const [startTimeError, setStartTimeError] = React.useState(null);
  const startTimeErrorMessage = React.useMemo(() => {
    switch (startTimeError) {
      case "maxTime": {
        return "Start Time > End Time";
      }

      default: {
        return "";
      }
    }
  }, [startTimeError]);

  const [hourError, setHourError] = React.useState(null);
  const hourErrorMessage = React.useMemo(() => {
    switch (hourError) {
      case "minTime": {
        return "At least 1 min";
      }

      default: {
        return "";
      }
    }
  }, [hourError]);

  const [editHourError, setEditHourError] = React.useState(null);
  const editHourErrorMessage = React.useMemo(() => {
    switch (editHourError) {
      case "minTime": {
        return "At least 1 min";
      }

      default: {
        return "";
      }
    }
  }, [editHourError]);

  const [endTimeError, setEndTimeError] = React.useState(null);
  const endTimeErrorMessage = React.useMemo(() => {
    switch (endTimeError) {
      case "minTime": {
        return "End Time < Start Time";
      }

      default: {
        return "";
      }
    }
  }, [endTimeError]);

  const handleMainFormFocus = () => {
    if (editing) {
      if (
        window.confirm(
          "Do you want to save changes to the currently edited row?"
        )
      ) {
        handleUpdate(editing);
      } else {
        setEditing(null);
      }
    }
  };

  const firstRowFieldRef = useRef(null);
  const handleEdit = (workHour) => {
    setEditing(workHour._id);
    setEditForm({
      date: dayjs(workHour.date),
      hours: workHour.hours,
      description: workHour.description,
      client: workHour.client,
      activity: workHour.activity,
      type: workHour.type,
    });
    setTimeout(() => {
      if (firstRowFieldRef.current) {
        firstRowFieldRef.current.focus();
      }
    }, 0);
  };

  const handleSubmit = async () => {
    if (await isSessionValid(authToken, onLogout)) {
      if (form.hours < MIN_HOURS_IN_SECONDS) {
        return;
      }

      if (validateForm() === false) {
        return;
      }

      const { _id, ...formData } = form;

      fetch(`${process.env.REACT_APP_API_URL}/workhours`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ ...formData, date: formData.date.utc().unix() }),
      })
        .then((response) => response.json())
        .then((newWorkHour) => {
          const updatedWorkHour = {
            ...newWorkHour,
            date: dayjs.unix(newWorkHour.date),
          };
          const updatedWorkHours = [...workHours, updatedWorkHour].sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
          setWorkHours(updatedWorkHours);

          if (
            form.description.trim() !== "" &&
            !suggestions.includes(form.description)
          ) {
            setSuggestions([...suggestions, form.description]);
          }

          const endTime = dayjs(updatedWorkHour.date).add(
            updatedWorkHour.hours,
            "seconds"
          );

          setForm({ ...form, date: endTime });
        })
        .catch((error) => console.error("Error adding work hour:", error));
    }
  };

  const handleUpdate = async (id) => {
    if (await isSessionValid(authToken, onLogout)) {
      const errors = {};
      if (!editForm.activity) errors.activity = "Client/Activity is required";
      if (!editForm.type) errors.type = "Type is required";

      const selectedActivity = activities.find(
        (activity) => activity._id === editForm.activity
      );
      if (selectedActivity && selectedActivity.name === "TeamWork task") {
        const match = editForm.description.match(
          new RegExp(
            `${process.env.REACT_APP_APPRIX_TASK_NO_PREFIX}([0-9]{5,})`
          )
        );
        if (!match) {
          errors.description = "Must contain TW id";
          alert("Description must contain TW id like #12345678.");
        }
      }

      if (
        editForm.hours < MIN_HOURS_IN_SECONDS ||
        Object.keys(errors).length > 0
      ) {
        return;
      }

      const submissionEditForm = {
        ...editForm,
        date: dayjs(editForm.date).utc().unix(),
      };

      const oldWorkHour = workHours.find((wh) => wh._id === id);
      const oldDescription = oldWorkHour ? oldWorkHour.description : null;

      fetch(`${process.env.REACT_APP_API_URL}/workhours/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(submissionEditForm),
      })
        .then((response) => response.json())
        .then((updatedWorkHour) => {
          setEditing(null);
          const convertedHandledWorkHour = {
            ...updatedWorkHour,
            date: dayjs.unix(updatedWorkHour.date),
          };
          const updatedWorkHours = workHours
            .map((wh) => (wh._id === id ? convertedHandledWorkHour : wh))
            .sort((a, b) => new Date(b.date) - new Date(a.date));
          setWorkHours(updatedWorkHours);

          if (oldDescription && oldDescription !== editForm.description) {
            const descriptionsInUseNo = updatedWorkHours.filter(
              (wh) => wh.description === oldDescription
            ).length;

            let suggestionsFiltered = suggestions;
            let suggestiongNeedupdate = false;
            if (descriptionsInUseNo === 0) {
              suggestionsFiltered = suggestions.filter(
                (description) => description !== oldDescription
              );
              suggestiongNeedupdate = true;
            }

            if (!suggestionsFiltered.includes(editForm.description)) {
              suggestionsFiltered = [
                ...suggestionsFiltered,
                editForm.description,
              ];
              suggestiongNeedupdate = true;
            }

            if (suggestiongNeedupdate) {
              setSuggestions(suggestionsFiltered);
            }
          }
        })
        .catch((error) => console.error("Error updating work hour:", error));
    }
  };

  const handleMainFormKeyDown = (e) => {
    if (!editing && e.key === "Enter") {
      if (!isPlaying) {
        handleSubmit();
      } else {
        handleStopClick();
      }
    }
  };

  const [onEditingTimeFieldsLockStates, setOnEditingTimesFieldLockStates] =
    useState({
      startTime: { default: "hours", other: "endTime", isDefault: true },
      hours: { default: "startTime", other: "endTime", isDefault: true },
      endTime: { default: "startTime", other: "hours", isDefault: true },
    });

  const handleTimeFieldsLockDisplayOnEditEvents = (
    fieldBeingEdited,
    onFocus
  ) => {
    //show the instruction text of the field being edited
    document.getElementById(fieldBeingEdited + "Instruction").style.visibility =
      onFocus ? "visible" : "hidden";

    //show/hide the lock icon for the default lock field of the field being edited
    document.getElementById(
      onEditingTimeFieldsLockStates[fieldBeingEdited].default + "Lock"
    ).style.visibility =
      onFocus && onEditingTimeFieldsLockStates[fieldBeingEdited].isDefault
        ? "visible"
        : "hidden";

    //show/hide the lock icon for the other lock field of the field being edited
    document.getElementById(
      onEditingTimeFieldsLockStates[fieldBeingEdited].other + "Lock"
    ).style.visibility =
      onFocus && !onEditingTimeFieldsLockStates[fieldBeingEdited].isDefault
        ? "visible"
        : "hidden";
  };

  const handleTimeFieldsLockChangesOnKeydown = (e, fieldBeingEdited) => {
    if (e.key === " ") {
      e.preventDefault();

      //hide the currently visible lock icon related to the field being edited
      document.getElementById(
        onEditingTimeFieldsLockStates[fieldBeingEdited].isDefault
          ? onEditingTimeFieldsLockStates[fieldBeingEdited].default + "Lock"
          : onEditingTimeFieldsLockStates[fieldBeingEdited].other + "Lock"
      ).style.visibility = "hidden";

      //show the currently invisible lock icon related to the field being edited
      document.getElementById(
        onEditingTimeFieldsLockStates[fieldBeingEdited].isDefault
          ? onEditingTimeFieldsLockStates[fieldBeingEdited].other + "Lock"
          : onEditingTimeFieldsLockStates[fieldBeingEdited].default + "Lock"
      ).style.visibility = "visible";

      //toggle the lock state of the field being edited
      setOnEditingTimesFieldLockStates((prev) => ({
        ...prev,
        [fieldBeingEdited]: {
          ...prev[fieldBeingEdited],
          isDefault: !prev[fieldBeingEdited].isDefault,
        },
      }));
    }
  };

  const handleEditFormKeyDown = (e) => {
    if (e.key === "Enter") {
      handleUpdate(editing);
    } else if (e.key === "Escape") {
      setEditing(null);
    }
  };

  const handleDelete = async (id) => {
    if (await isSessionValid(authToken, onLogout)) {
      if (!window.confirm("Are you sure you want to delete this entry?")) {
        return;
      }
      fetch(`${process.env.REACT_APP_API_URL}/workhours/${id}`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${authToken}` },
      })
        .then(() => setWorkHours(workHours.filter((wh) => wh._id !== id)))
        .catch((error) => console.error("Error deleting work hour:", error));
    }
  };

  const displayTimePeriod = (start, hours) => {
    const end = dayjs(start).add(hours, "seconds");
    return `${dayjs(start).format("HH:mm")} - ${end.format("HH:mm")}`;
  };

  const displayHours = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.round((seconds % 3600) / 60);
    const paddedHours = hours.toString().padStart(2, "0");
    const paddedMinutes = minutes.toString().padStart(2, "0");
    return `${paddedHours}:${paddedMinutes}`;
  };

  const calculateTotalHours = (workHours, filterFn) => {
    const totalSeconds = workHours
      .filter(filterFn)
      .reduce((acc, curr) => acc + curr.hours, 0);
    return displayHours(totalSeconds);
  };

  const getStartOfWeek = (date) => {
    //dayjs's start of the week day is Sun
    //getting the right Mon could also be done smarter using dayjs/plugin/isoWeek
    //but that would require a plugin and this is simple enough
    const day = date.day();
    const diff = (day === 0 ? -6 : 1) - day;
    return date.add(diff, "day").startOf("day");
  };

  const today = dayjs().startOf("day");
  const startOfWeek = getStartOfWeek(dayjs());
  const startOfMonth = dayjs().startOf("month");

  const totalToday = calculateTotalHours(workHours, (workHour) =>
    dayjs(workHour.date).isSame(today, "day")
  );

  const totalWeek = calculateTotalHours(workHours, (workHour) =>
    dayjs(workHour.date).isAfter(startOfWeek)
  );

  const totalMonth = calculateTotalHours(workHours, (workHour) =>
    dayjs(workHour.date).isAfter(startOfMonth)
  );

  const StyledTableHeaderCell = styled(TableCell)(({ span }) => {
    const totalColumns = 16;
    const width = (span / totalColumns) * 100;
    return {
      width: `${width}%`,
      padding: 0,
      borderBottom: "none",
    };
  });

  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    border: "none",
    "&:hover": {
      border: "none",
      backgroundColor: "transparent",
      "& .MuiSvgIcon-root": {
        transform: "scale(1.3)",
      },
    },
    "& .MuiSvgIcon-root": {
      transition: "transform 0.5s ease",
    },
  }));

  const groupWorkHoursByDate = (workHours) => {
    const groups = workHours.reduce((groups, workHour) => {
      const dateObj = dayjs(workHour.date);
      const today = dayjs().startOf("day");
      const yesterday = dayjs().subtract(1, "day").startOf("day");
      const dateFormat = "D.M.";
      const dateFormatWithYear = "D.M.YYYY";

      let date;
      if (dateObj.isSame(today, "day")) {
        date = `Today, ${dateObj.format(dateFormat)}`;
      } else if (dateObj.isSame(yesterday, "day")) {
        date = `Yesterday, ${dateObj.format(dateFormat)}`;
      } else {
        //check if date older than 1 year
        const yearDiff = dayjs().diff(dateObj, "year");
        if (yearDiff < 1) {
          date = dateObj.format("dddd, " + dateFormat);
        } else {
          date = dateObj.format("dddd, " + dateFormatWithYear);
        }
      }

      if (!groups[date]) {
        groups[date] = { totalHours: 0, workHours: [] };
      }
      groups[date].totalHours += workHour.hours;
      groups[date].workHours.push(workHour);
      return groups;
    }, {});

    return Object.entries(groups).map(([date, group]) => ({
      date,
      totalHours: group.totalHours,
      workHours: group.workHours,
    }));
  };

  const groupedWorkHours = groupWorkHoursByDate(workHours);

  const validateForm = useCallback(() => {
    const errors = {};
    if (!form.activity) errors.activity = "Client/Activity is required";
    if (!form.type) errors.type = "Type is required";

    /*const forbiddenCharsRegex = /[äåöÄÅÖ]/;
    if (forbiddenCharsRegex.test(form.description)) {
      errors.description = "No chars like 'ä', 'å', 'ö'.";
    }*/

    const selectedActivity = activities.find(
      (activity) => activity._id === form.activity
    );
    if (selectedActivity && selectedActivity.name === "TeamWork task") {
      const match = form.description.match(
        new RegExp(`${process.env.REACT_APP_APPRIX_TASK_NO_PREFIX}([0-9]{5,})`)
      );
      if (!match) {
        errors.description = "Must contain TW id";
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return false;
    }

    setFormErrors({});
    return true;
  }, [form, activities]);

  const displayRunningHours = (seconds) => {
    const h = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const m = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const s = String(seconds % 60).padStart(2, "0");
    return `${h}:${m}:${s}`;
  };

  const handlePlayClick = useCallback(async () => {
    if (await isSessionValid(authToken, onLogout)) {
      if (validateForm() === false) {
        return;
      }

      const hoursToUse = 0;
      const dateToUse = dayjs();

      setForm({ ...form, date: dateToUse, hours: hoursToUse });

      setIsPlaying(true);
      setRunningTime(hoursToUse);

      timerRef.current = setInterval(() => {
        setRunningTime((prevTime) => prevTime + 1);
      }, 1000);

      fetch(`${process.env.REACT_APP_API_URL}/workhours/start`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          ...form,
          date: dateToUse.unix(),
          hours: hoursToUse,
        }),
      })
        .then((response) => response.json())
        .then((newWorkHour) => {
          setRunningTimerId(newWorkHour._id);
        })
        .catch((error) => console.error("Error starting timer:", error));
    }
  }, [authToken, form, onLogout, validateForm]);

  const handleStopClick = async () => {
    if (await isSessionValid(authToken, onLogout)) {
      setIsPlaying(false);
      clearInterval(timerRef.current);

      const roundedRunningTime = Math.round(runningTime / 60) * 60;

      setForm({ ...form, hours: roundedRunningTime });

      if (roundedRunningTime >= MIN_HOURS_IN_SECONDS) {
        fetch(
          `${process.env.REACT_APP_API_URL}/workhours/stop/${runningTimerId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({
              hours: roundedRunningTime,
              description: form.description,
              client: form.client,
              activity: form.activity,
              type: form.type,
            }),
          }
        )
          .then((response) => response.json())
          .then((updatedWorkHour) => {
            setFormErrors({});
            setHourError(null);
            setForm({ ...form, hours: DEFAULT_HOURS_IN_SECONDS });
            const convertedUpdatedWorkHour = {
              ...updatedWorkHour,
              date: dayjs.unix(updatedWorkHour.date),
            };
            const updatedWorkHours = [
              ...workHours,
              convertedUpdatedWorkHour,
            ].sort((a, b) => new Date(b.date) - new Date(a.date));
            setWorkHours(updatedWorkHours);
          })
          .catch((error) => console.error("Error stopping timer:", error));
      } else {
        setForm({ ...form, hours: 0 });
        delete form._id;
        fetch(`${process.env.REACT_APP_API_URL}/workhours/${runningTimerId}`, {
          method: "DELETE",
          headers: { Authorization: `Bearer ${authToken}` },
        }).catch((error) => console.error("Error deleting work hour:", error));
      }
    }
  };

  const [shouldPlay, setShouldPlay] = useState(false);
  useEffect(() => {
    if (shouldPlay) {
      handlePlayClick();
      setShouldPlay(false);
    }
  }, [shouldPlay, form, handlePlayClick]);

  const handlePlayFromRow = (workHour) => {
    const { description, activity, client, type } = workHour;

    const obj = {
      ...form,
      description,
      activity,
      client,
      type,
      date: dayjs(),
    };
    setShouldPlay(true);
    setForm(obj);
  };

  const autoCompleteFilterOptions = createFilterOptions({
    ignoreAccents: true,
    ignoreCase: true,
    limit: 7,
    matchFrom: "any",
    trim: true,
  });

  return (
    <React.Fragment>
      {!loading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            m: 2,
          }}
        >
          <img src={loop4Img} alt="Loop Logo" style={{ height: "50px" }} />
          <Box sx={{ textAlign: "center", flexGrow: 1 }}>
            <Box sx={{ color: "primary.main" }}>
              Today: {totalToday} <span style={{ whiteSpace: "pre" }}> </span>|
              <span style={{ whiteSpace: "pre" }}> </span> Week: {totalWeek}{" "}
              <span style={{ whiteSpace: "pre" }}> </span>|
              <span style={{ whiteSpace: "pre" }}> </span> Month: {totalMonth}
            </Box>
          </Box>
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onViewChange();
            }}
            underline="none"
            color={"secondary"}
            sx={{ cursor: "pointer", marginRight: 2 }}
          >
            filter
          </Link>
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onLogout();
            }}
            underline="none"
            color={"secondary"}
            sx={{ cursor: "pointer", marginLeft: "auto" }}
          >
            logout
          </Link>
        </Box>
      )}
      {loading && (
        <Paper
          sx={{
            minHeight: 56,
          }}
        />
      )}
      {!loading && (
        <Paper
          sx={{
            m: 2,
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(60px, 1fr))",
            gap: 2,
            gridAutoFlow: "row dense",
          }}
        >
          <Autocomplete
            freeSolo
            options={suggestions}
            filterOptions={autoCompleteFilterOptions}
            value={form.description}
            onInputChange={(event, newValue) => {
              handleChange({
                target: { name: "description", value: newValue },
              });
            }}
            onChange={(event, newValue) => {
              handleAutocomplete(event, newValue);
            }}
            sx={{ gridColumn: "span 4", minWidth: 100 }}
            renderInput={(params) => (
              <Box>
                <TextField
                  {...params}
                  label="Description"
                  name="description"
                  onFocus={handleMainFormFocus}
                  onKeyDown={handleMainFormKeyDown}
                  error={!!formErrors.description}
                />
                {formErrors.description && (
                  <Box sx={{ color: "red", mt: 1 }}>
                    {formErrors.description}
                  </Box>
                )}
              </Box>
            )}
          />
          <FormControl
            sx={{ gridColumn: "span 2" }}
            error={!!formErrors.activity}
          >
            <InputLabel id="client-activity-label">Client/Activity</InputLabel>
            <Select
              labelId="client-activity-label"
              name="activity"
              value={form.activity}
              onFocus={handleMainFormFocus}
              onChange={(e) => handleChange(e, null)}
              onKeyDown={handleMainFormKeyDown}
              label="Client/Activity"
            >
              {generateClientActivityMenuItems()}
            </Select>
            {formErrors.activity && (
              <Box sx={{ color: "red", mt: 1 }}>{formErrors.activity}</Box>
            )}
          </FormControl>
          <FormControl
            sx={{ gridColumn: "span 2", minWidth: 100 }}
            error={!!formErrors.type}
          >
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              labelId="type-label"
              name="type"
              value={form.type}
              onFocus={handleMainFormFocus}
              onChange={(e) => handleChange(e, null)}
              onKeyDown={handleMainFormKeyDown}
              label="Type"
            >
              {types.map((type) => (
                <MenuItem key={type._id} value={type._id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
            {formErrors.type && (
              <Box sx={{ color: "red", mt: 1 }}>{formErrors.type}</Box>
            )}
          </FormControl>
          {!isPlaying ? (
            <>
              <Box
                sx={{ gridColumn: "span 2", position: "relative" }}
                onFocus={(e) => {
                  handleMainFormFocus();
                  handleTimeFieldsLockDisplayOnEditEvents("startTime", true);
                }}
                onBlur={(e) => {
                  handleTimeFieldsLockDisplayOnEditEvents("startTime", false);
                }}
                onKeyDown={(e) => {
                  handleMainFormKeyDown(e);
                  handleTimeFieldsLockChangesOnKeydown(e, "startTime");
                }}
              >
                <Box
                  id="startTimeInstruction"
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    right: "-9px",
                    "z-index": 10,
                    visibility: "hidden",
                  }}
                >
                  <Tooltip
                    title="When updating the Start Time, either the End Time or the Hours will automatically adjust. Press the Spacebar to select which of the field shall remain unchanged during the update."
                    placement="left-end"
                  >
                    <Box
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                        width: 20,
                        height: 20,
                      }}
                    >
                      <InfoIcon sx={{ color: grey[500] }} />
                    </Box>
                  </Tooltip>
                </Box>
                <Box
                  id="startTimeLock"
                  sx={{
                    position: "absolute",
                    top: "-12px",
                    right: "-8px",
                    "z-index": 10,
                    visibility: "hidden",
                  }}
                >
                  <Tooltip
                    title="This field is locked and won't be adjusted on updates. Press the Spacebar to unlock."
                    placement="left-end"
                  >
                    <LockIcon color="warning" />
                  </Tooltip>
                </Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Start Time"
                    value={form.date}
                    onChange={(date) => handleStartTimeChange(date)}
                    onOpen={() => {
                      handleMainFormFocus();
                      handleTimeFieldsLockDisplayOnEditEvents(
                        "startTime",
                        true
                      );
                    }}
                    onClose={() => {
                      setTimeout(() => {
                        handleTimeFieldsLockDisplayOnEditEvents(
                          "startTime",
                          false
                        );
                      }, 10);
                    }}
                    format="D.M. H:mm"
                    maxDateTime={
                      document.getElementById("startTimeInstruction") &&
                      document.getElementById("startTimeInstruction").style
                        .visibility === "visible" &&
                      onTimeFieldEditIsOtherTimeFieldLocked(
                        "startTime",
                        "endTime"
                      )
                        ? dayjs(form.date)
                            .add(form.hours, "seconds")
                            .subtract(MIN_HOURS_IN_SECONDS, "seconds")
                        : null
                    }
                    onError={(newError) => setStartTimeError(newError)}
                    slotProps={{
                      textField: {
                        helperText: startTimeErrorMessage,
                      },
                      field: { shouldRespectLeadingZeros: true },
                    }}
                    ampm={false}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <Box
                sx={{ gridColumn: "span 2", position: "relative" }}
                onFocus={(e) => {
                  handleMainFormFocus();
                  handleTimeFieldsLockDisplayOnEditEvents("hours", true);
                }}
                onBlur={(e) => {
                  handleTimeFieldsLockDisplayOnEditEvents("hours", false);
                }}
                onKeyDown={(e) => {
                  handleMainFormKeyDown(e);
                  handleTimeFieldsLockChangesOnKeydown(e, "hours");
                }}
              >
                <Box
                  id="hoursInstruction"
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                    "z-index": 10,
                    visibility: "hidden",
                  }}
                >
                  <Tooltip
                    title="When updating Hours, either the Start Time or the End Time will automatically adjust. Press the Spacebar to select which of the fields shall remain unchanged during the update."
                    placement="left-end"
                  >
                    <Box
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                        width: 20,
                        height: 20,
                      }}
                    >
                      <InfoIcon sx={{ color: grey[500] }} />
                    </Box>
                  </Tooltip>
                </Box>
                <Box
                  id="hoursLock"
                  sx={{
                    position: "absolute",
                    top: "-12px",
                    right: "-8px",
                    "z-index": 10,
                    visibility: "hidden",
                  }}
                >
                  <Tooltip
                    title="This field is locked and won't be adjusted on updates. Press the Spacebar to unlock."
                    placement="left-end"
                  >
                    <LockIcon color="warning" />
                  </Tooltip>
                </Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Hours"
                    value={dayjs("1970-01-01T00:00:00").add(
                      form.hours,
                      "seconds"
                    )}
                    onChange={(time) => handleHoursChange(time)}
                    onOpen={() => {
                      handleMainFormFocus();
                      handleTimeFieldsLockDisplayOnEditEvents("hours", true);
                    }}
                    onClose={() => {
                      setTimeout(() => {
                        handleTimeFieldsLockDisplayOnEditEvents("hours", false);
                      }, 10);
                    }}
                    format="H:mm"
                    minTime={dayjs("1970-01-01T00:01:00")}
                    onError={(newError) => setHourError(newError)}
                    slotProps={{
                      textField: {
                        helperText: hourErrorMessage,
                      },
                      field: { shouldRespectLeadingZeros: true },
                    }}
                    ampm={false}
                  />
                </LocalizationProvider>
              </Box>
              <Box
                sx={{ gridColumn: "span 2", position: "relative" }}
                onFocus={(e) => {
                  handleMainFormFocus();
                  handleTimeFieldsLockDisplayOnEditEvents("endTime", true);
                }}
                onBlur={(e) => {
                  handleTimeFieldsLockDisplayOnEditEvents("endTime", false);
                }}
                onKeyDown={(e) => {
                  handleMainFormKeyDown(e);
                  handleTimeFieldsLockChangesOnKeydown(e, "endTime");
                }}
              >
                <Box
                  id="endTimeInstruction"
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                    "z-index": 10,
                    visibility: "hidden",
                  }}
                >
                  <Tooltip
                    title="When updating End Time, either the Start Time or the Hours will automatically adjust. Press the Spacebar to select which of the fields shall remain unchanged during the update."
                    placement="left-end"
                  >
                    <Box
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                        width: 20,
                        height: 20,
                      }}
                    >
                      <InfoIcon sx={{ color: grey[500] }} />
                    </Box>
                  </Tooltip>
                </Box>
                <Box
                  id="endTimeLock"
                  sx={{
                    position: "absolute",
                    top: "-12px",
                    right: "-8px",
                    "z-index": 10,
                    visibility: "hidden",
                  }}
                >
                  <Tooltip
                    title="This field is locked and won't be adjusted on updates. Press the Spacebar to unlock."
                    placement="left-end"
                  >
                    <LockIcon color="warning" />
                  </Tooltip>
                </Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="End Time"
                    value={dayjs(form.date).add(form.hours, "seconds")}
                    onChange={(date) => handleEndTimeChange(date)}
                    onOpen={() => {
                      handleMainFormFocus();
                      handleTimeFieldsLockDisplayOnEditEvents("endTime", true);
                    }}
                    onClose={() => {
                      setTimeout(() => {
                        handleTimeFieldsLockDisplayOnEditEvents(
                          "endTime",
                          false
                        );
                      }, 10);
                    }}
                    format="D.M. H:mm"
                    minDateTime={
                      document.getElementById("endTimeInstruction") &&
                      document.getElementById("endTimeInstruction").style
                        .visibility === "visible" &&
                      onTimeFieldEditIsOtherTimeFieldLocked(
                        "endTime",
                        "startTime"
                      )
                        ? dayjs(form.date).add(MIN_HOURS_IN_SECONDS, "seconds")
                        : null
                    }
                    onError={(newError) => setEndTimeError(newError)}
                    slotProps={{
                      textField: {
                        helperText: endTimeErrorMessage,
                      },
                      field: { shouldRespectLeadingZeros: true },
                    }}
                    ampm={false}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <Button
                onFocus={handleMainFormFocus}
                onClick={handleSubmit}
                color="primary"
                variant="contained"
                sx={{
                  gridColumn: "span 1",
                  minWidth: 56,
                  minHeight: 56,
                  maxHeight: 56,
                }}
              >
                <AddIcon />
              </Button>
            </>
          ) : (
            <>
              <Box sx={{ gridColumn: "span 3", minWidth: 195 }} />
              <Box
                sx={{
                  gridColumn: "span 2",
                  minWidth: 110,
                  lineHeight: "56px",
                  textAlign: "center",
                  fontSize: "0.9rem",
                  marginLeft: -1.5,
                }}
              >
                {displayRunningHours(runningTime)}
              </Box>
              <Box sx={{ gridColumn: "span 1", minWidth: 56 }} />
            </>
          )}
          {!isPlaying ? (
            <Button
              onFocus={handleMainFormFocus}
              onClick={handlePlayClick}
              color="primary"
              variant="contained"
              sx={{
                gridColumn: "span 1",
                minWidth: 56,
                minHeight: 56,
                maxHeight: 56,
              }}
            >
              <PlayArrowIcon />
            </Button>
          ) : (
            <Button
              onFocus={handleMainFormFocus}
              onClick={handleStopClick}
              color="primary"
              variant="contained"
              sx={{
                gridColumn: "span 1",
                minWidth: 56,
                minHeight: 56,
                maxHeight: 56,
              }}
            >
              <StopIcon />
            </Button>
          )}
        </Paper>
      )}

      <Box sx={{ m: 2 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableHeaderCell span={4}>
                  {/* Description */}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell span={2}>
                  {/* Client/Activity */}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell span={2}>
                  {/* Type */}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell span={2}>
                  {/* Date */}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell span={2}>
                  {/* Hours */}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell span={2}>
                  {/* Empty */}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell span={2} sx={{ textAlign: "center" }}>
                  {/* Actions */}
                </StyledTableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupedWorkHours.map(({ date, totalHours, workHours }) => (
                <React.Fragment key={date}>
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      sx={{
                        fontSize: "1rem",
                        paddingLeft: 1,
                        paddingTop: 5,
                      }}
                    >
                      {date}
                    </TableCell>
                    <TableCell
                      sx={{
                        paddingTop: 5,
                        textAlign: "center",
                      }}
                    >
                      {displayHours(totalHours)}
                    </TableCell>
                    <TableCell colSpan={2} />
                  </TableRow>
                  {workHours.map((workHour) => (
                    <TableRow
                      key={workHour._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        sx={{
                          paddingTop: editing === workHour._id ? "default" : 0,
                          paddingBottom:
                            editing === workHour._id ? "default" : 0,
                          paddingLeft: 1,
                          paddingRight: 1,
                        }}
                      >
                        {editing === workHour._id ? (
                          <TextField
                            name="description"
                            label="Description"
                            value={editForm.description}
                            onChange={(e) => handleChange(e, "editForm")}
                            onKeyDown={handleEditFormKeyDown}
                            inputRef={firstRowFieldRef}
                            fullWidth
                          />
                        ) : (
                          workHour.description
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          paddingTop: editing === workHour._id ? "default" : 0,
                          paddingBottom:
                            editing === workHour._id ? "default" : 0,
                          paddingLeft: 1,
                          paddingRight: 1,
                        }}
                      >
                        {editing === workHour._id ? (
                          <FormControl fullWidth>
                            <InputLabel id="edit-client-activity-label">
                              Client/Activity
                            </InputLabel>
                            <Select
                              labelId="edit-client-activity-label"
                              name="activity"
                              value={editForm.activity}
                              onChange={(e) => handleChange(e, "editForm")}
                              onKeyDown={handleEditFormKeyDown}
                              label="Client/Activity"
                            >
                              {generateClientActivityMenuItems()}
                            </Select>
                          </FormControl>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {getClientActivityDisplay(workHour)}
                          </Box>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          paddingTop: editing === workHour._id ? "default" : 0,
                          paddingBottom:
                            editing === workHour._id ? "default" : 0,
                          paddingLeft: 1,
                          paddingRight: 1,
                        }}
                      >
                        {editing === workHour._id ? (
                          <FormControl fullWidth>
                            <InputLabel id="edit-type-label">Type</InputLabel>
                            <Select
                              labelId="edit-type-label"
                              name="type"
                              value={editForm.type}
                              onChange={(e) => handleChange(e, "editForm")}
                              onKeyDown={handleEditFormKeyDown}
                              label="Type"
                            >
                              {types.map((type) => (
                                <MenuItem key={type._id} value={type._id}>
                                  {type.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {types.find((type) => type._id === workHour.type)
                              ?.name || ""}
                          </Box>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          paddingTop: editing === workHour._id ? "default" : 0,
                          paddingBottom:
                            editing === workHour._id ? "default" : 0,
                          paddingLeft: 1,
                          paddingRight: 1,
                        }}
                      >
                        {editing === workHour._id ? (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label="Start Time"
                              value={editForm.date}
                              onChange={(date) =>
                                handleStartTimeChange(date, "editForm")
                              }
                              textField={(params) => (
                                <TextField
                                  {...params}
                                  onKeyDown={handleEditFormKeyDown}
                                />
                              )}
                              ampm={false}
                              format="D.M. HH:mm"
                              slotProps={{
                                field: { shouldRespectLeadingZeros: true },
                              }}
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                            />
                          </LocalizationProvider>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {displayTimePeriod(workHour.date, workHour.hours)}
                          </Box>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          paddingTop: editing === workHour._id ? "default" : 0,
                          paddingBottom:
                            editing === workHour._id ? "default" : 0,
                          paddingLeft: 1,
                          paddingRight: 1,
                        }}
                      >
                        {editing === workHour._id ? (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              label="Hours"
                              value={dayjs("1970-01-01T00:00:00").add(
                                editForm.hours,
                                "seconds"
                              )}
                              onChange={(time) =>
                                handleHoursChange(time, "editForm")
                              }
                              onKeyDown={handleEditFormKeyDown}
                              minTime={dayjs("1970-01-01T00:01:00")}
                              onError={(newError) => setEditHourError(newError)}
                              slotProps={{
                                textField: {
                                  helperText: editHourErrorMessage,
                                },
                              }}
                              ampm={false}
                            />
                          </LocalizationProvider>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {displayHours(workHour.hours)}
                          </Box>
                        )}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          paddingTop: editing === workHour._id ? "default" : 0,
                          paddingBottom:
                            editing === workHour._id ? "default" : 0,
                          paddingLeft: 1,
                          paddingRight: 1,
                        }}
                      >
                        {editing === workHour._id ? (
                          <StyledIconButton
                            onClick={() => handleUpdate(workHour._id)}
                            color="primary"
                          >
                            <SaveIcon />
                          </StyledIconButton>
                        ) : (
                          <>
                            <StyledIconButton
                              color="secondary"
                              onClick={() => handleEdit(workHour)}
                            >
                              <EditIcon />
                            </StyledIconButton>
                            <StyledIconButton
                              color="secondary"
                              onClick={() => handleDelete(workHour._id)}
                            >
                              <DeleteIcon />
                            </StyledIconButton>
                            <StyledIconButton
                              color="secondary"
                              onClick={() => handlePlayFromRow(workHour)}
                            >
                              <PlayArrowIcon />
                            </StyledIconButton>
                          </>
                        )}
                        {editing === workHour._id && (
                          <StyledIconButton
                            onClick={() => setEditing(null)}
                            color="secondary"
                          >
                            <CloseIcon />
                          </StyledIconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  );
};

export default WorkHoursTable;
