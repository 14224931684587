import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const root = ReactDOM.createRoot(document.getElementById("root"));
const colors = {
  primary: "#74c378",
  primaryDark: "#50b455",
  primaryDarker: "#47a44b",
  warning: "#fc8c2a",
  warningDark: "#f07203",
  warningDarker: "#d66603",
  error: "#ec3c2f",
  errorDark: "#d52113",
  errorDarker: "#bd1d11",
  white: "#ffffff",
  mediumGray: "#d3d3d380",
  transparentGray: "rgba(0, 0, 0, 0.1)",
};

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.mediumGray,
    },
    warning: {
      main: colors.warning,
    },
    error: {
      main: colors.error,
    },
    mode: "dark",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          border: `1px solid ${colors.primaryDark}`,
          backgroundImage: `linear-gradient(180deg, ${colors.primary} 0%, ${colors.primaryDark} 100%)`,
          color: colors.white,
          "&:active:not(.busy):not(:disabled), &.active": {
            boxShadow: `inset 2px 2px 10px ${colors.transparentGray}`,
          },
          "&:hover:not(.busy):not(:active):not(:disabled)": {
            backgroundImage: `linear-gradient(180deg, ${colors.primary} 0%, ${colors.primaryDarker} 100%)`,
          },
        },
        containedWarning: {
          border: `1px solid ${colors.warningDark}`,
          backgroundImage: `linear-gradient(180deg, ${colors.warning} 0%, ${colors.warningDark} 100%)`,
          color: colors.white,
          "&:active:not(.busy):not(:disabled), &.active": {
            boxShadow: `inset 2px 2px 10px ${colors.transparentGray}`,
          },
          "&:hover:not(.busy):not(:active):not(:disabled)": {
            backgroundImage: `linear-gradient(180deg, ${colors.warning} 0%, ${colors.warningDarker} 100%)`,
          },
        },
        containedError: {
          border: `1px solid ${colors.errorDark}`,
          backgroundImage: `linear-gradient(180deg, ${colors.error} 0%, ${colors.errorDark} 100%)`,
          color: colors.white,
          "&:active:not(.busy):not(:disabled), &.active": {
            boxShadow: `inset 2px 2px 10px ${colors.transparentGray}`,
          },
          "&:hover:not(.busy):not(:active):not(:disabled)": {
            backgroundImage: `linear-gradient(180deg, ${colors.error} 0%, ${colors.errorDarker} 100%)`,
          },
        },
      },
    },
  },
});

if (process.env.NODE_ENV !== "production") {
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </React.StrictMode>
  );
} else {
  root.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
