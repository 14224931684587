import React, { useState, useEffect, useCallback } from "react";
import WorkHoursTable from "./WorkHoursTable";
import FilterTable from "./FilterTable";
import { Login, Register } from "./AuthComponents";
import { Box, Link } from "@mui/material";
import { isSessionValid } from "./utils";

const App = () => {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("token") || null
  );
  const [showRegister, setShowRegister] = useState(false);
  const [showFilterView, setShowFilterView] = useState(false);

  const handleLogout = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
        method: "POST",
        headers: { Authorization: `Bearer ${authToken}` },
      });
      if (response.ok) {
        setAuthToken(null);
        localStorage.removeItem("token");
      } else {
        //make sure to remove token on logout
        setAuthToken(null);
        localStorage.removeItem("token");
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  }, [authToken]);

  useEffect(() => {
    const checkToken = async () => {
      if (authToken) {
        const valid = await isSessionValid(authToken);
        if (!valid) {
          handleLogout();
        }
      }
    };
    checkToken();
  }, [authToken, handleLogout]);

  const handleRegister = async (userData) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/register`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(userData),
        }
      );
      if (response.ok) {
        console.log("User registered successfully");
        setShowRegister(false);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || "Registration failed");
      }
    } catch (error) {
      console.error("Registration error:", error);
      throw error;
    }
  };

  const handleLogin = async (email, password) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, password }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        setAuthToken(data.token);
        localStorage.setItem("token", data.token);
        console.clear();
      } else {
        console.error("Login failed");
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const handleViewChange = async () => {
    if (await isSessionValid(authToken, handleLogout)) {
      setShowFilterView((prev) => !prev);
    }
  };

  return (
    <div>
      {!authToken ? (
        showRegister ? (
          <Register onRegister={handleRegister} />
        ) : (
          <Login onLogin={handleLogin} />
        )
      ) : (
        <>
          {showFilterView ? (
            <FilterTable
              authToken={authToken}
              onLogout={handleLogout}
              onViewChange={handleViewChange}
            />
          ) : (
            <WorkHoursTable
              authToken={authToken}
              onLogout={handleLogout}
              onViewChange={handleViewChange}
            />
          )}
        </>
      )}

      {!authToken && (
        <Box sx={{ ml: 2 }}>
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShowRegister(!showRegister);
            }}
            underline="none"
            color={"secondary"}
            sx={{ cursor: "pointer", marginTop: 20 }}
          >
            {showRegister ? "... or login" : "... or register"}
          </Link>
        </Box>
      )}
    </div>
  );
};

export default App;
