export const isSessionValid = async (authToken, handleLogout) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/validate-session`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );
    if (!response.ok) {
      if (handleLogout) {
        handleLogout();
      } else {
        localStorage.removeItem("token");
        window.location.reload();
      }
      return false;
    }
    return true;
  } catch (error) {
    if (handleLogout) {
      handleLogout();
    } else {
      localStorage.removeItem("token");
      window.location.reload();
    }
    return false;
  }
};
