import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Link,
} from "@mui/material";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import ClearIcon from "@mui/icons-material/Clear";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import SyncIcon from "@mui/icons-material/Sync";
import Tooltip from "@mui/material/Tooltip";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/material/styles";
import loop4Img from "./loop4.png";
import { isSessionValid } from "./utils";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Helsinki");

dayjs.updateLocale("en", {
  weekStart: 1, // Set Monday as the first day of the week, for right display in calendars
});

const FilterTable = ({ authToken, onLogout, onViewChange }) => {
  const [filters, setFilters] = useState({
    user: [],
    description: "",
    client: "",
    activity: "",
    type: "",
    startTime: dayjs().startOf("month").startOf("day"),
    endTime: dayjs(),
  });
  const [workHours, setWorkHours] = useState([]);
  const [clients, setClients] = useState([]);
  const [activities, setActivities] = useState([]);
  const [types, setTypes] = useState([]);
  const [users, setUsers] = useState([]);
  //const [loading, setLoading] = useState(true);
  const [userSelectOpen, setUserSelectOpen] = useState(false);
  const [filtersAltered, setFiltersAltered] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          clientsResponse,
          activitiesResponse,
          typesResponse,
          usersResponse,
        ] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/clients`, {
            headers: { Authorization: `Bearer ${authToken}` },
          }),
          fetch(`${process.env.REACT_APP_API_URL}/activities`, {
            headers: { Authorization: `Bearer ${authToken}` },
          }),
          fetch(`${process.env.REACT_APP_API_URL}/types`, {
            headers: { Authorization: `Bearer ${authToken}` },
          }),
          fetch(`${process.env.REACT_APP_API_URL}/users`, {
            headers: { Authorization: `Bearer ${authToken}` },
          }),
        ]);

        const [clientsData, activitiesData, typesData, usersData] =
          await Promise.all([
            clientsResponse.json(),
            activitiesResponse.json(),
            typesResponse.json(),
            usersResponse.json(),
          ]);

        setClients(clientsData);
        setActivities(activitiesData);
        setTypes(typesData);
        setUsers(usersData);
        //setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [authToken]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "dropdown") {
      const selectedItem = dropdownItems.find((item) => item.id === value);
      if (selectedItem.type === "client") {
        setFilters({
          ...filters,
          client: value,
          activity: "",
        });
      } else {
        setFilters({
          ...filters,
          client: "",
          activity: value,
        });
      }
    } else {
      setFilters({ ...filters, [name]: value });
    }

    if (name === "user") {
      setUserSelectOpen(false);
    }

    setFiltersAltered(true);
  };

  const handleDateChange = (date, name) => {
    setFilters({ ...filters, [name]: date });
    setFiltersAltered(true);
  };

  const handleFilter = async () => {
    if (await isSessionValid(authToken, onLogout)) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/workhours/filter`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({
              ...filters,
              startTime: filters.startTime.utc().unix(),
              endTime: filters.endTime.utc().unix(),
            }),
          }
        );
        const data = await response.json();
        setWorkHours(data);
        setFiltersAltered(false);
      } catch (error) {
        console.error("Error filtering work hours:", error);
      }
    }
  };

  const handleClear = () => {
    setFilters({
      user: [],
      description: "",
      client: "",
      activity: "",
      type: "",
      startTime: dayjs(),
      endTime: dayjs(),
    });
    setWorkHours([]);
    setFiltersAltered(false);
  };

  const displayHours = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.round((seconds % 3600) / 60);
    const paddedHours = hours.toString().padStart(2, "0");
    const paddedMinutes = minutes.toString().padStart(2, "0");
    return `${paddedHours}:${paddedMinutes}`;
  };

  const calculateTotalHours = (workHours) => {
    if (!Array.isArray(workHours) || workHours.length === 0) {
      return displayHours(0);
    }
    const totalSeconds = workHours.reduce((acc, curr) => acc + curr.hours, 0);
    return displayHours(totalSeconds);
  };

  const StyledTableHeaderCell = styled(TableCell)(({ span }) => {
    const totalColumns = 18;
    const width = (span / totalColumns) * 100;
    return {
      width: `${width}%`,
      padding: 0,
      borderBottom: "none",
    };
  });

  const handleFilterKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFilter();
    }
  };

  const getClientActivityDisplay = (workHour) => {
    const activity = activities.find(
      (activity) => activity._id === workHour.activity
    );
    const client = clients.find((client) => client._id === activity?.clientId);
    return client ? `${client.name}: ${activity.name}` : activity?.name || "";
  };

  const getDropdownItems = () => {
    const items = [];
    if (clients.length > 0)
      clients.forEach((client) => {
        items.push({ type: "client", id: client._id, name: client.name });
        activities
          .filter((activity) => activity.clientId === client._id)
          .forEach((activity) => {
            items.push({
              type: "activity",
              id: activity._id,
              name: activity.name,
            });
          });
      });
    return items;
  };
  const dropdownItems = getDropdownItems();

  //const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const generateCsv = async () => {
    if (filtersAltered) {
      await handleFilter();
    }

    if (await isSessionValid(authToken, onLogout)) {
      let clientId = filters.client;
      if (!clientId && filters.activity) {
        const activity = activities.find((act) => act._id === filters.activity);
        clientId = activity ? activity.clientId : null;
      }

      if (!clientId) {
        alert("Select a client or an activity to download the CSV.");
        return;
      }

      const params = {
        clientId: clientId,
        activityId: filters.activity,
        startDate: filters.startTime.utc().unix(),
        endDate: filters.endTime.utc().unix(),
        userIds: filters.user.join(","),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/download-csv`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(params),
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        const contentDisposition = response.headers.get("Content-Disposition");
        let filename = "workhours.csv";
        if (
          contentDisposition &&
          contentDisposition.indexOf("attachment") !== -1
        ) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
        }
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        console.error("Failed to download CSV");
      }
    }
  };

  const generateReport = async () => {
    if (filtersAltered) {
      await handleFilter();
    }

    if (await isSessionValid(authToken, onLogout)) {
      let clientId = filters.client;
      if (!clientId && filters.activity) {
        const activity = activities.find((act) => act._id === filters.activity);
        clientId = activity ? activity.clientId : null;
      }

      if (!clientId) {
        alert("Select a client or an activity to generate the pdf report.");
        return;
      }

      const params = {
        clientId: clientId,
        activityId: filters.activity,
        startDate: filters.startTime.utc().unix(),
        endDate: filters.endTime.utc().unix(),
        userIds: filters.user.join(","),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/generate-report`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(params),
        }
      );

      if (response.ok) {
        const data = await response.json();
        const filePath = `${process.env.REACT_APP_PDF_URL}/${data.filePath}`;

        fetch(filePath, {
          method: "GET",
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = data.filePath.split("/").pop();
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          })
          .catch((err) => console.error("Error downloading the file:", err));
      } else {
        console.error("Failed to generate report");
      }
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          m: 2,
        }}
      >
        <img src={loop4Img} alt="Loop Logo" style={{ height: "50px" }} />
        <Box sx={{ textAlign: "center", flexGrow: 1 }}>
          <Box sx={{ color: "primary.main" }}>
            Total: {calculateTotalHours(workHours)}
          </Box>
        </Box>
        <Link
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onViewChange();
          }}
          underline="none"
          color={"secondary"}
          sx={{ cursor: "pointer", marginRight: 2 }}
        >
          track
        </Link>
        <Link
          href="#"
          onClick={async (e) => {
            e.preventDefault();
            generateCsv();
          }}
          underline="none"
          color={"secondary"}
          sx={{ cursor: "pointer", marginRight: 2 }}
        >
          csv
        </Link>
        <Link
          href="#"
          onClick={(e) => {
            e.preventDefault();
            generateReport();
          }}
          underline="none"
          color={"secondary"}
          sx={{ cursor: "pointer", marginRight: 2 }}
        >
          pdf
        </Link>
        <Link
          href="#"
          onClick={async (e) => {
            e.preventDefault();
            const month = prompt(
              "Sync your personal yet unsynced TW hours to Apprix.\nEnter a month during the last year (1-12):"
            );
            if (month) {
              try {
                const response = await fetch(
                  `${process.env.REACT_APP_API_URL}/tw-sync`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${authToken}`,
                    },
                    body: JSON.stringify({ month: parseInt(month, 10) }),
                  }
                );

                if (response.ok) {
                  const data = await response.json();
                  alert(data.message);
                  handleFilter();
                } else {
                  const data = await response.json();
                  const details = data.details
                    ? "\n\nDetails:\n" + data.details.join("\n")
                    : "";
                  alert(`${data.error}${details}`);
                }
              } catch (error) {
                console.error("Error initiating sync:", error);
                alert("An error occurred while initiating synchronization.");
              }
            }
          }}
          underline="none"
          color={"secondary"}
          sx={{ cursor: "pointer", marginRight: 2 }}
        >
          tw
        </Link>
        <Link
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onLogout();
          }}
          underline="none"
          color={"secondary"}
          sx={{ cursor: "pointer", marginLeft: "auto" }}
        >
          logout
        </Link>
      </Box>
      <Paper
        sx={{
          m: 2,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(60px, 1fr))",
          gap: 2,
          gridAutoFlow: "row dense",
        }}
      >
        <FormControl
          sx={{ gridColumn: "span 2", minWidth: 80 }}
          onKeyDown={handleFilterKeyDown}
        >
          <InputLabel id="user-label">User</InputLabel>
          <Select
            labelId="user-label"
            multiple
            name="user"
            value={filters.user}
            onChange={handleChange}
            onClose={() => setUserSelectOpen(false)}
            onOpen={() => setUserSelectOpen(true)}
            open={userSelectOpen}
            label="User"
          >
            {users.map((user) => (
              <MenuItem
                key={user._id}
                value={user._id}
              >{`${user.firstName} ${user.lastName}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          sx={{ gridColumn: "span 4", minWidth: 100 }}
          label="Description"
          name="description"
          value={filters.description}
          onChange={handleChange}
          onKeyDown={handleFilterKeyDown}
        />
        <FormControl
          sx={{ gridColumn: "span 2", minWidth: 100 }}
          onKeyDown={handleFilterKeyDown}
        >
          <InputLabel id="client-activity-label">Client / Activity</InputLabel>
          <Select
            labelId="client-activity-label"
            name="dropdown"
            value={filters.client || filters.activity}
            onChange={handleChange}
            label="Client / Activity"
          >
            {dropdownItems.map((item, index) => (
              <MenuItem
                key={index}
                value={item.id}
                style={{
                  fontWeight: item.type === "client" ? "bold" : "normal",
                }}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{ gridColumn: "span 2", minWidth: 100 }}
          onKeyDown={handleFilterKeyDown}
        >
          <InputLabel id="type-label">Type</InputLabel>
          <Select
            labelId="type-label"
            name="type"
            value={filters.type}
            onChange={handleChange}
            label="Type"
          >
            {types.map((type) => (
              <MenuItem key={type._id} value={type._id}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            onKeyDown={handleFilterKeyDown}
            label="Start Time"
            value={filters.startTime}
            onChange={(date) => handleDateChange(date, "startTime")}
            textField={(params) => <TextField {...params} />}
            ampm={false}
            format="D.M.YYYY HH:mm"
            slotProps={{ field: { shouldRespectLeadingZeros: true } }}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            sx={{ gridColumn: "span 3", minWidth: 195 }}
          />
          <DateTimePicker
            onKeyDown={handleFilterKeyDown}
            label="End Time"
            value={filters.endTime}
            onChange={(date) => handleDateChange(date, "endTime")}
            textField={(params) => <TextField {...params} />}
            ampm={false}
            format="D.M.YYYY HH:mm"
            slotProps={{ field: { shouldRespectLeadingZeros: true } }}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            sx={{ gridColumn: "span 3", minWidth: 195 }}
          />
        </LocalizationProvider>
        <Button
          onClick={handleFilter}
          color="primary"
          variant="contained"
          sx={{
            gridColumn: "span 1",
            minWidth: 56,
            minHeight: 56,
            maxHeight: 56,
          }}
        >
          <PublishedWithChangesIcon />
        </Button>
        <Button
          onClick={handleClear}
          color="primary"
          variant="contained"
          sx={{
            gridColumn: "span 1",
            minWidth: 56,
            minHeight: 56,
            maxHeight: 56,
          }}
        >
          <ClearIcon />
        </Button>
      </Paper>
      <Box sx={{ m: 2 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableHeaderCell span={2}>
                  {/* User */}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell span={4}>
                  {/* Description */}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell span={2}>
                  {/* Client / Activity */}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell span={2}>
                  {/* Type */}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell span={6}>
                  {/* Time */}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell span={2}>
                  {/* Hours */}
                </StyledTableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workHours.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    Select and apply filters to view data
                  </TableCell>
                </TableRow>
              ) : (
                workHours.map((workHour) => (
                  <TableRow
                    key={workHour._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {
                        users.find((user) => user._id === workHour.userId)
                          ?.firstName
                      }
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {workHour.description}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {getClientActivityDisplay(workHour)}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {types.find((type) => type._id === workHour.type)?.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {`${dayjs
                          .unix(workHour.date)
                          .format("ddd, D.M.YYYY, HH:mm")} - ${dayjs
                          .unix(workHour.date)
                          .add(workHour.hours, "second")
                          .format("HH:mm")}`}
                        {workHour.synced ? (
                          <Tooltip title="Synced ok.">
                            <CheckCircleIcon sx={{ ml: 1 }} />
                          </Tooltip>
                        ) : workHour.syncStatus.startedTime ? (
                          workHour.syncStatus.errorDetails ? (
                            <Tooltip title={workHour.syncStatus.errorDetails}>
                              <ErrorIcon sx={{ ml: 1 }} />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={`Sync with id ${workHour.syncStatus.startedTime} in progress...`}
                            >
                              <SyncIcon sx={{ ml: 1 }} />
                            </Tooltip>
                          )
                        ) : (
                          ""
                        )}
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {`${Math.floor(workHour.hours / 3600)}:${Math.floor(
                        (workHour.hours % 3600) / 60
                      )
                        .toString()
                        .padStart(2, "0")}`}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  );
};

export default FilterTable;
